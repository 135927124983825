import React, { Component } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import {
  inviteMemberToTeam,
  removeMemberFromTeam,
  searchTeamUsers,
  updateMemberRole,
  updateTeamSettings,
} from '../../../common/api/endpoints/team';
import Joi from 'joi';
import TextBox from '../../controls/TextBox/TextBox';
import Button from '../../controls/Button/Button';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import Thumbnail from '../Thumbnail/Thumbnail';
import ManageTeamMember from './ManageTeamMember';
import { DEFAULT_AVATAR } from '../../../common/configs/appDefaults';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { customEmailValidation } from '../../pages/Auth/Registration/helper/customEmailValidation';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  fetchTeam: () => Promise<void>;
  history: any;
}

interface State {
  status: TRequestStatus;
  serverErrors: string[];
  isValidEmail: boolean;
  isValidEmailMember: boolean;
  isValidHandle: boolean;
  searchUsers: (MemberDTO | InviteeDTO)[] | null;
  memberQuery: string;
}
export class TeamMembers extends Component<Props, State> {
  emailSchema = Joi.string()
    .required()
    .trim(true)
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .custom(customEmailValidation);

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      serverErrors: [],
      isValidEmail: false,
      isValidEmailMember: false,
      isValidHandle: false,
      searchUsers: null,
      memberQuery: '',
    };
  }

  searchMembers = async () => {
    try {
      const search = await searchTeamUsers(
        this.props.data?.id || '',
        this.state.memberQuery,
      );
      let searchMembers: (MemberDTO | InviteeDTO)[] = [];

      if (search.hasOwnProperty('invitees')) {
        for (const invitee of search.invitees) {
          invitee.invited = true;
        }
      }

      if (
        search.hasOwnProperty('invitees') &&
        search.hasOwnProperty('members')
      ) {
        searchMembers = [...search.invitees, ...search.members];
      }

      if (search.isValidHandle) {
        this.setState({
          isValidHandle: true,
          searchUsers: [],
        });
      } else {
        this.setState({
          isValidHandle: false,
          searchUsers: searchMembers,
        });
      }
    } catch (err) {
      this.setState({
        isValidHandle: false,
        searchUsers: [],
      });
    }
  };

  inviteMember = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const member = await inviteMemberToTeam(
        this.props.data?.id || '',
        this.state.memberQuery,
      );
      this.setMemberQuery('');

      this.setState({
        status: 'success',
      });

      this.props.fetchTeam();
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  updateTeamMemberRole = async (id: string, role: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await updateMemberRole(id, role);

      this.setState({
        status: 'success',
      });

      this.setMemberQuery('');
      this.props.fetchTeam();
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  removeMember = async (id: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await removeMemberFromTeam(id);

      this.setState({
        status: 'success',
      });

      this.setMemberQuery('');
      this.props.fetchTeam();
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  handleInputEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.state.isValidEmail && e.key === 'Enter') {
      this.inviteMember();
    }
  };

  setIsValidEmail = () => {
    const result = this.emailSchema.validate(this.state.memberQuery, {
      abortEarly: false,
    });

    this.setState({
      isValidEmail: !Boolean(result.error),
    });
  };

  setMemberQuery = (value: string) => {
    this.setState(
      {
        memberQuery: value,
      },
      () => {
        this.setIsValidEmail();
        this.searchMembers();
      },
    );
  };

  getSearchLabel = () => {
    let label = 'name';

    if (this.state.memberQuery.startsWith('@')) {
      label = 'handle';
    } else if (this.state.isValidEmail) {
      label = 'email';
    }

    return label;
  };

  purchaseAndInvite = async () => {
    if (this.props.data) {
      try {
        await updateTeamSettings(this.props.data.id, {
          name: this.props.data.name,
          seats: this.props.data.seats + 1,
        });

        this.inviteMember();

        this.setState({
          status: 'success',
        });
      } catch (err) {
        this.setState({
          status: 'error',
        });
      }
    }
  };

  renderNoSeatsInvite = (isValidQuery: boolean) => {
    const { t } = this.props;
    return (
      <>
        {this.props.data?.owned ? (
          <>
            <Button
              className="primary-button mt-xs flex-h-start"
              disabled={!isValidQuery || this.state.status === 'loading'}
              onClick={() => {
                this.inviteMember();
              }}
            >
              <>
                <span className="icon fas fa-user-plus"></span>
                <span className="text">
                  <span className="faint-text-green">
                    {t('purchaseSeatInviteText')}
                  </span>{' '}
                  <span>{t('userText')}</span>
                  <span className="faint-text-green"> </span>
                </span>
              </>
            </Button>
            <div className="flex-row">
              <div className="column medium">
                <p className="flag-text-blue px-xs py-xs">
                  <span className="fas fa-exclamation-circle accent-text-blue"></span>{' '}
                  <span>{t('noSeatsLeftText')}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <Button
              className="primary-button mt-xs flex-h-start"
              disabled={true}
            >
              <>
                <span className="icon fas fa-user-plus"></span>
                <span className="text">
                  <span className="faint-text-green">{t('inviteText')}</span>{' '}
                  <span>{t('userText')}</span>
                  <span className="faint-text-green"> </span>
                </span>
              </>
            </Button>
            <div className="flex-row">
              <div className="column medium">
                <p className="flag-text-blue px-xs py-xs">
                  <span className="fas fa-exclamation-circle accent-text-blue"></span>{' '}
                  <span>{t('noSeatsLeftAdminText')}</span>
                </p>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  renderInviteUser = (isValidQuery: boolean, availableSeats: number) => {
    const { t } = this.props;
    return (
      <>
        <Button
          className="primary-button mt-xs flex-h-start"
          disabled={!isValidQuery || this.state.status === 'loading'}
          onClick={this.inviteMember}
        >
          <>
            <span className="icon fas fa-user-plus"></span>
            <span className="text">
              <span className="faint-text-green">{t('inviteText')}</span>{' '}
              <span>{t('userText')}</span>
              <span className="faint-text-green"> </span>
            </span>
          </>
        </Button>
        <div className="flex-row">
          <div className="column medium">
            <p className="flag-text-green px-xs py-xs">
              <span className="fas fa-info-circle accent-text-green"></span>{' '}
              <span>{t('availableSeatsText', { count: availableSeats })}</span>
            </p>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { t } = this.props;
    let members = this.state.searchUsers || [];
    const isAlreadyATeamMember = members?.some((member) => {
      if ('email' in member) {
        return member.email === this.state.memberQuery;
      }

      return Boolean(member.avatar);
    });

    let isValidQuery =
      (this.state.isValidEmail || this.state.isValidHandle) &&
      !isAlreadyATeamMember;

    let availableSeats = 0;

    if (this.props.data) {
      availableSeats =
        this.props.data?.seats -
        this.props.data?.members.length -
        this.props.data?.invitees.length;
    }

    return (
      <>
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="team-members"
              isOpen={true}
              iconClasses="fal fa-users icon"
              title={t('teamMembersTitle')}
              subheading={t('manageTeamMembersSubheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              history={this.props.history}
            >
              <div className="accordion-row py-sm">
                <div className="flex-row">
                  <div className="column">
                    <p className="faint-text text-sm">
                      {t('filterOrInviteMembersText')}
                    </p>
                  </div>
                </div>
                <TextBox
                  type="text"
                  name="userSearch"
                  id="userSearch"
                  value={this.state.memberQuery}
                  onChange={(e) => this.setMemberQuery(e.target.value)}
                  srOnly={true}
                  label={t('nameEmailLabel')}
                  placeholder={t('nameEmailLabel')}
                  onKeyDown={this.handleInputEnterKey}
                  autoComplete="off"
                  ariaAutocomplete="none"
                />
                <div className="tableview-component fill table-h-scroll">
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="fill">
                          <span>{t('nameLabel')}</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.memberQuery &&
                        members.map((member, index) => {
                          return (
                            <tr>
                              <td></td>
                              <td>
                                <div className="flex-row fill">
                                  <div className="column flex-v-center">
                                    <Thumbnail
                                      classes="size-32"
                                      avatarData={
                                        member.avatar
                                          ? member.avatar
                                          : DEFAULT_AVATAR
                                      }
                                      title={member.name}
                                    />
                                    <div>
                                      {'email' in member && (
                                        <span className="text">
                                          {member.email}
                                        </span>
                                      )}
                                      {'name' in member && (
                                        <span className="text">
                                          {member.name}
                                        </span>
                                      )}
                                      {'invited' in member && (
                                        <span className="text faint-text">
                                          {member.invited
                                            ? ` (${t('invitedText')})`
                                            : ''}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  removeMember={this.removeMember}
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {!this.state.memberQuery &&
                        this.props.data?.members.map((member, index) => {
                          return (
                            <tr>
                              <td></td>
                              <td>
                                <div className="flex-row fill">
                                  <div className="column flex-v-center">
                                    <Thumbnail
                                      classes="size-32"
                                      avatarData={member.avatar}
                                      title={member.name}
                                    />
                                    <div>
                                      {'email' in member && (
                                        <span className="text">
                                          {member.email}
                                        </span>
                                      )}
                                      {'name' in member && (
                                        <span className="text">
                                          {member.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  removeMember={this.removeMember}
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {!this.state.memberQuery &&
                        this.props.data?.invitees.map((member, index) => {
                          return (
                            <tr>
                              <td></td>
                              <td>
                                <div className="flex-row fill">
                                  <div className="column flex-v-center">
                                    <Thumbnail
                                      classes="size-32"
                                      avatarData={
                                        member.avatar
                                          ? member.avatar
                                          : DEFAULT_AVATAR
                                      }
                                      title={member.name}
                                    />
                                    <div>
                                      {'email' in member && (
                                        <span className="text">
                                          {member.email}
                                        </span>
                                      )}
                                      {'name' in member && (
                                        <span className="text">
                                          {member.name}
                                        </span>
                                      )}
                                      <span className="text faint-text">
                                        {` (${t('invitedText')})`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  removeMember={this.removeMember}
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {this.state.memberQuery && !members.length && (
                  <div className="fill">
                    <p className="faint-text separator text-chunk mt-sm text-sm">
                      {t('noMembersFoundText', {
                        searchLabel: this.getSearchLabel(),
                      })}
                      {availableSeats <= 0 && isValidQuery
                        ? this.renderNoSeatsInvite(isValidQuery)
                        : availableSeats >= 1 &&
                          isValidQuery &&
                          this.renderInviteUser(isValidQuery, availableSeats)}
                    </p>
                  </div>
                )}
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('teamMembers')(TeamMembers);
