import React from 'react';
import AppContext, {
  IAppContext,
  NotificationMessage,
} from '../../../common/contexts/AppContext';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext, {
  IBoardContext,
} from '../../../common/contexts/BoardContext';
import { cn } from '../../../common/helpers/cn';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}

interface ExternalProps {
  accordionSlug: string;
  title?: string;
  subheading?: string;
  disabled?: boolean;
  isOpen?: boolean;
  iconClasses?: string;
  hideArrow?: boolean;
  softDisabled?: boolean;
  loading?: boolean;
  history?: any;
  onToggle?: (isOpen: boolean) => void; // Added onToggle prop
  translucent?: boolean;
}

interface State {
  isOpen: boolean;
  accordionId: string;
}

interface BoardContextProps {}

interface Props extends ExternalProps, AppContextProps, BoardContextProps {}
class Accordion extends React.Component<Props, State> {
  accordionRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.accordionRef = React.createRef();
    this.state = {
      isOpen: this.props.isOpen || false,
      accordionId: this.props.accordionSlug,
    };
  }

  componentDidMount(): void {
    if (window.location.href.includes(this.props.accordionSlug)) {
      this.updateAccordionOnId();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.accordionSlug !== this.props.accordionSlug) {
      this.setState({
        accordionId: this.props.accordionSlug,
      });
      if (window.location.href.includes(this.props.accordionSlug)) {
        this.updateAccordionOnId();
      }
    }
  }

  toggleAccordion = () => {
    this.setState((prevState: State) => {
      const isOpen = !prevState.isOpen;
      if (this.props.onToggle) {
        this.props.onToggle(isOpen);
      }
      return { isOpen };
    });
  };

  updateAccordionOnId = () => {
    // if you have the #accordionSlug in your url, the accordion should be open and you should be scrolled to it
    this.setState({
      isOpen: true,
    });
    window.scrollTo({
      top: this.accordionRef.current?.offsetTop,
      behavior: 'smooth',
    });

    // clear url of hash
    const { pathname } = this.props.history?.location;
    this.props.history.replace(pathname);
  };

  render() {
    return (
      <>
        <div
          className="form-group mb-0"
          ref={this.accordionRef}
          id={this.props.accordionSlug}
        >
          <div
            className={cn({
              accordion: true,
              open: this.state.isOpen && !this.props.loading,
            })}
          >
            <button
              className={cn({
                'accordion-button': true,
                'soft-disabled': this.props.softDisabled ? true : false,
                'hide-arrow': this.props.hideArrow ? true : false,
                translucent: this.props.translucent ? true : false,
              })}
              disabled={this.props.disabled}
              onClick={this.toggleAccordion}
            >
              <div className="flex-row no-reflow squeeze flex-v-center">
                <div className="column py-0">
                  <span className={this.props.iconClasses}></span>
                </div>
                <div className="column py-0">
                  <span>{this.props.title}</span>
                  {this.props.subheading && (
                    <>
                      <br />
                      <small className="faint-text">
                        {this.props.subheading}
                      </small>
                    </>
                  )}
                </div>
              </div>
              <span className="icon-helper fas fa-angle-down"></span>
            </button>
            <div className="accordion-body">
              <div
                className={cn({
                  'accordion-inner': true,
                  translucent: this.props.translucent ? true : false,
                })}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};

const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      updateCard: ctx.updateCard,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(Accordion, AppContextAdapter, BoardContextAdapter);
