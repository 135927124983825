import React, { ChangeEvent, FormEvent } from 'react';
import { TAG_COLORS } from '../../../common/configs/tag';
import Button from '../../controls/Button/Button';
import TextBox from '../../controls/TextBox/TextBox';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { UserDTO } from '../../../common/api/dtos/User';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Joi from 'joi';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';

interface FormData {
  id: string;
  name: string;
  color: string;
}

interface Props extends WithTranslation {
  handleSubmit: (boardTagData: TagDTO) => void;
  legendText: string;
  selectedTag?: TagDTO | null;
  serverErrors: string[];
  loggedUser: UserDTO;
  setSelectedContext?: (context: TContextMenu) => void;
}

interface State {
  formData: FormData;
  formErrors: { [key in keyof FormData]?: string };
}

const schema = Joi.object({
  id: Joi.string().allow(''),
  name: Joi.string()
    .pattern(/^[a-zA-Z0-9 ~\-'\.]*$/)
    .min(1)
    .trim()
    .required()
    .messages({
      'string.pattern.base':
        "The name can only contain letters, numbers, spaces, apostrophes ('), hyphens (-), and periods (.)",
      'string.empty': 'The name cannot be empty',
      'any.required': 'The name is required',
    }),
  color: Joi.string().required(),
});

class TagForm extends React.Component<Props, State> {
  defaultColor: string;

  constructor(props: Props) {
    super(props);
    this.defaultColor = localStorage.getItem(
      `${this.props.loggedUser.id}-lastTagColor`,
    )
      ? (localStorage.getItem(
          `${this.props.loggedUser.id}-lastTagColor`,
        ) as string)
      : TAG_COLORS[0].value;

    this.state = {
      formData: {
        id: this.props.selectedTag?.id || '',
        name: this.props.selectedTag?.name || '',
        color: this.props.selectedTag?.color || this.defaultColor,
      },
      formErrors: {},
    };
  }

  validateForm = (): boolean => {
    const { error } = schema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (error) {
      const formErrors: { [key in keyof FormData]?: string } = {};
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof FormData;
        formErrors[key] = detail.message;
      });
      this.setState({ formErrors });
      return false;
    }
    this.setState({ formErrors: {} });
    return true;
  };

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }),
      this.validateForm,
    );
  }

  setTagName = (ev: ChangeEvent<HTMLInputElement>) => {
    this.updateFormData('name', ev.target.value);
  };

  setTagColor = (value: string) => {
    localStorage.setItem(`${this.props.loggedUser.id}-lastTagColor`, value);
    this.updateFormData('color', value);
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.handleSubmit(this.state.formData);
    }
  };

  render() {
    const { t } = this.props;
    const { formData, formErrors } = this.state;

    return (
      <fieldset id="addTagForm">
        <legend>{this.props.legendText}</legend>
        <ul className="control-list-component unwrappable">
          {TAG_COLORS.map((color) => {
            const isSelected = formData.color === color.value;
            return (
              <li key={color.value}>
                <Button
                  className={`secondary-button ${
                    isSelected ? 'active selection-border' : ''
                  }`}
                  value={formData.color}
                  onClick={() => this.setTagColor(color.value)}
                >
                  <span className={`icon accent-text-${color.value}`}>
                    <span className={`cb-only ${color.symbol}`}></span>
                    <span className={`cb-none fas fa-tag`}></span>
                  </span>
                </Button>
              </li>
            );
          })}
        </ul>
        <div className="flex-row fill tagInputForm">
          <div className="column">
            <form onSubmit={this.handleSubmit}>
              <TextBox
                label={t('tagNameLabel')}
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={this.setTagName}
                srOnly={true}
                placeholder={t('tagNameLabel')}
                maxLength={30}
                showCounter={false}
                className="tagInput"
                error={formErrors.name}
              />
              <ul className="control-list-component">
                <li>
                  <Button
                    className="primary-button"
                    type="submit"
                  >
                    {t('saveTag')}
                  </Button>
                </li>
                <li>
                  <Button
                    className="ghost-button"
                    title={t('closeForm')}
                    onClick={() =>
                      this.props.setSelectedContext &&
                      this.props.setSelectedContext('TagManageMenus')
                    }
                  >
                    <span className="fas fa-times pe-none"></span>
                  </Button>
                </li>
              </ul>
            </form>
            {/* Additional actions like `Delete Tag` */}
            {this.props.children}
          </div>
        </div>
      </fieldset>
    );
  }
}

export default withStyledTranslation('tagForm')(TagForm);
