import React, { Component } from 'react';

interface Props {
  label: string;
  name: string;
  id: string;
  minValue: number;
  maxValue: number;
  hideStepper?: boolean;

  onChange?: (ev: React.ChangeEvent<HTMLInputElement> | number) => void;
  autoComplete?: string;
  ariaAutocomplete?: 'none' | 'inline' | 'list' | 'both';
  br?: boolean;
  placeholder?: string;
  srOnly?: boolean;
  value?: number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  className?: string;
  tight?: boolean;
  autofocus?: boolean;
  formGroupClassNames?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface State {
  focused: boolean;
  value?: string;
}

class NumberControl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value?.toString() || '',
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value?.toString() || '',
      });
    }
  }

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      focused: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  increment = () => {
    if (this.props.disabled) return;
    const currentValue = this.state.value
      ? parseInt(this.state.value, 10)
      : this.props.minValue;

    if (currentValue < this.props.maxValue) {
      const newValue = currentValue + 1;
      this.setState({ value: newValue.toString() });
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }
  };

  decrement = () => {
    if (this.props.disabled) return;

    const currentValue = this.state.value
      ? parseInt(this.state.value, 10)
      : this.props.minValue;

    if (currentValue > this.props.minValue) {
      const newValue = currentValue - 1;
      this.setState({ value: newValue.toString() });
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }
  };

  handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const { minValue, maxValue } = this.props;
    const key = ev.key;
    let value = ev.currentTarget.value;

    // Prevent leading zeros
    if (key === '0' && value === '0') {
      ev.preventDefault();
      return;
    }

    // Handle backspace key
    if (key === 'Backspace') {
      value = value.slice(0, -1);
    }

    if (key === 'Delete') {
      ev.preventDefault();
    }

    if (key === 'Backspace' && Number(value) < this.props.minValue) {
      console.log(Number(value));
      // If backspace makes the input empty, reset it to minValue
      this.setState({ value: minValue.toString() });
      ev.preventDefault();
      if (this.props.onChange) {
        this.props.onChange(minValue);
      }
      return;
    }

    const proposedValue = parseInt(value + key, 10);

    // Ensure valid range on direct input
    if (!isNaN(proposedValue)) {
      if (proposedValue < minValue) {
        this.setState({ value: minValue.toString() });
        ev.preventDefault();
        if (this.props.onChange) {
          this.props.onChange(minValue);
        }
      } else if (proposedValue > maxValue) {
        this.setState({ value: maxValue.toString() });
        ev.preventDefault();
        if (this.props.onChange) {
          this.props.onChange(maxValue);
        }
      }
    }
  };

  handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let value = ev.target.value;

    // Trim leading zeros
    value = value.replace(/^0+/, '');

    // If the input becomes empty after trimming, default it to the minimum value
    if (value === '') {
      value = this.props.minValue.toString();
    }

    this.setState({ value });

    if (this.props.onChange) {
      const numericValue = parseInt(value, 10);
      this.props.onChange(numericValue);
    }
  };

  render() {
    return (
      <div
        className={[
          'form-group',
          this.props.formGroupClassNames,
          this.props.tight ? 'tight' : '',
        ].join(' ')}
      >
        <label
          className={`${this.props.srOnly ? 'sr-only' : ''}`}
          htmlFor={this.props.id}
        >
          {this.props.label}
          {this.props.required ? '*' : ''}
        </label>
        {this.props.br && <br />}
        <div className="number-control">
          <input
            type="number"
            name={this.props.name}
            id={this.props.id}
            autoComplete={this.props.autoComplete}
            aria-autocomplete={this.props.ariaAutocomplete}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            value={this.state.value}
            required={this.props.required}
            onBlur={this.onBlur}
            aria-invalid={`${!!this.props.error}`}
            autoFocus={this.props.autofocus}
            className={this.props.className}
            onKeyDown={(e) => {
              this.handleKeyDown(e);
              if (this.props.onKeyDown) {
                this.props.onKeyDown(e);
              }
            }}
            onFocus={this.onFocus}
            min={this.props.minValue}
            max={this.props.maxValue}
            pattern="\d*"
            maxLength={this.props.maxValue.toString().length}
          />
          {!this.props.hideStepper && (
            <div className="control-wrapper">
              <button
                className="ghost-button"
                type="button"
                disabled={
                  this.props.disabled ||
                  this.state.value === this.props.maxValue.toString()
                }
                onClick={(e) => {
                  this.increment();
                  e.preventDefault();
                }}
              >
                <span className="fas fa-angle-up"></span>
              </button>
              <button
                className="ghost-button"
                type="button"
                disabled={
                  this.props.disabled ||
                  this.state.value === this.props.minValue.toString()
                }
                onClick={(e) => {
                  this.decrement();
                  e.preventDefault();
                }}
              >
                <span className="fas fa-angle-down"></span>
              </button>
            </div>
          )}
        </div>
        {this.props.error && (
          <ul className="error-list light">
            <li>{this.props.error}</li>
          </ul>
        )}
      </div>
    );
  }
}

export default NumberControl;
