import React from 'react';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface SubscriptionStatusProps {
  team: TeamDTO;
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({ team }) => {
  const { t } = useTranslation('home');

  if (team.subscription.deleteAt && team.owned) {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <div className="card px-xs py-xs">
            <div className="content py-xs">
              <p>
                <span className="fas px-xs accent-text-red fa-exclamation-circle"></span>
                {t('planExpired', {
                  teamName: team.name,
                  deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                    'MMMM Do, YYYY',
                  ),
                })}
                <br />
                {t('avoidDeletionPrefix')}{' '}
                <NavLink
                  to={`/team/${team.id}/plan-and-billing`}
                  className={'link-button'}
                >
                  <span className="text">{t('avoidDeletionMid')}</span>
                </NavLink>{' '}
                {t('avoidDeletionSuffix')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (team.subscription.cancelAt && team.owned) {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <div className="card px-xs py-xs">
            <div className="content py-xs">
              <p>
                <span className="fas px-xs accent-text-yellow fa-exclamation-circle "></span>
                The plan for <strong>{team.name}</strong> expires{' '}
                <strong>
                  {dayjsHelper(team.subscription.cancelAt).format(
                    'MMMM Do, YYYY',
                  )}
                </strong>
                .<br />
                {t('avoidGracePrefix')}{' '}
                <NavLink
                  to={`/team/${team.id}/plan-and-billing`}
                  className={'link-button'}
                >
                  <span className="text">{t('avoidGraceMid')}</span>
                </NavLink>{' '}
                {t('avoidGraceSuffix')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SubscriptionStatus;
