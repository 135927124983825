import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { listArchivedCards } from '../../../../common/api/endpoints/card';
import BoardContext, {
  IBoard,
  IBoardContext,
  ICard,
} from '../../../../common/contexts/BoardContext';
import { getFilteredItems } from '../../../../common/helpers/getFilteredItems';
import { IRestoreColumn } from '../../../../common/interfaces/RestoreColumn';
import { TCardData } from '../../../../common/types/CardData';
import { withContextAdapter } from '../../../partials/ContextAdapter/withContextAdapter';
import Flyout from '../../../partials/Flyout/Flyout';
import BoardTopbar from '../../../partials/Topbar/BoardTopbar';
import BoardCard from '../../../partials/board/BoardCard/BoardCard';
import BoardArchivedCardFlyout from '../../../partials/board/BoardCardFlyout/BoardArchivedCardFlyout';
import { BoardCard as FlyoutBoardCard } from '../../../partials/board/BoardCardFlyout/BoardCardFlyout';
import {
  addCardToUrl,
  removeCardFromUrl,
} from '../../../../common/helpers/cardUrl';
import scrollToCard from '../../../../common/helpers/scrollToCard';
import { getScrollBehavior } from '../../../../common/helpers/getScrollBehavior';
import {
  MsgDeleteCard,
  MsgRestoreCard,
  MsgWs,
} from '../BoardProvider/wsHandlers';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';

interface RouteParams {
  routeBoardId: string;
  cardId?: string;
}

interface ContextProps {
  board: IBoard;
  toggleFavorite: () => void;
  setArchivedCards?: (cards: ArchivedCard[] | null) => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
}

interface ExternalProps extends RouteComponentProps<RouteParams> {
  className?: string;
}

interface Props extends ExternalProps, ContextProps, WithTranslation {}

interface FormData {
  name: string;
  description: string;
  prefix: string;
  color: string;
}

export type ArchivedCard = ICard & {
  columnId: string | null;
  numberOfAttachments: number;
  numberOfComments: number;
};

interface State {
  filterValue: string;
  formData: FormData;
  restoreColumns: IRestoreColumn[];
  serverErrors: string[];
  selectedCardData: TCardData[];
  isClosing: boolean;
  archivedFilters: string[];
  flyoutCard?: { id: string; columnId: string | null } | null;
}

class BoardArchivedCards extends Component<Props, State> {
  availableFilters: string[] = ['title'];
  cardRefs: Record<string, React.RefObject<HTMLDivElement>>;

  constructor(props: Props) {
    super(props);

    this.cardRefs = {};

    this.state = {
      filterValue: '',
      formData: {
        name: 'MACRO',
        description: 'A board managing the macro processes of the company',
        prefix: '#MCR-',
        color: 'green',
      },
      restoreColumns: [],
      serverErrors: [],
      selectedCardData: ['cardNumber'],
      isClosing: false,
      archivedFilters: [
        'title',
        'priority',
        'cardNumber',
        'description',
        'member',
        'tag',
      ],
    };
  }

  componentDidMount(): void {
    this.fetchArchivePageProps();
    this.props.addWsListener('restore_card', this.handleWsCloseFlyout);
    this.props.addWsListener('delete_card', this.handleWsCloseFlyout);
  }

  componentDidUpdate(prevProps: Props) {
    let oldCardId = prevProps.match.params.cardId;
    let newCardId = this.props.match.params.cardId;

    if (this.props.board.archivedCards?.length && oldCardId !== newCardId) {
      this.selectCardFromUrl(this.props.board.archivedCards, newCardId);
    }
  }

  componentWillUnmount(): void {
    if (this.props.setArchivedCards) {
      this.props.setArchivedCards(null);
    }
    this.props.removeWsListener('restore_card', this.handleWsCloseFlyout);
    this.props.removeWsListener('delete_card', this.handleWsCloseFlyout);
  }

  // close the flyout when ws restore card message is received
  handleWsCloseFlyout = (message: MsgWs) => {
    const wsMessage = message as MsgRestoreCard | MsgDeleteCard;

    if (
      this.state.flyoutCard &&
      wsMessage &&
      wsMessage.data.id === this.state.flyoutCard.id
    ) {
      this.setState({
        flyoutCard: null,
      });
    }
  };

  selectCardFromUrl = (cards: ArchivedCard[], cardId?: string) => {
    if (!cardId) {
      this.clearFlyoutCard();
    } else {
      const card = cards.find((card) => card.id === cardId);
      if (card) {
        this.setFlyoutCard(card.columnId!, card);
      } else {
        this.props.history.replace(`/not-found`);
      }
    }
  };

  fetchArchivePageProps = async () => {
    try {
      // we need to make sure both of these fetches succeed in order to have  fully functionaly `Archive Cards` page
      // so we'll use the Promise.all() method in order to catch if at least on of them fails
      const [archived] = await Promise.all([
        listArchivedCards(this.props.match.params.routeBoardId),
      ]);

      const restoreColumns = this.props.board.columns.map((column) => {
        return {
          id: column.id,
          title: column.title,
        };
      });

      if (this.props.match.params.cardId) {
        this.selectCardFromUrl(
          archived.cards as ArchivedCard[],
          this.props.match.params.cardId,
        );
      }

      if (this.props.setArchivedCards) {
        this.props.setArchivedCards(archived.cards as ArchivedCard[]);
      }

      this.setState({
        restoreColumns: restoreColumns,
      });
    } catch (err) {
      console.log(err);
    }
  };

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    });
  };

  removeCardFromList = (cardId: string) => {
    // Remove the card and then select the first card if there is one
    // or close the flyout.getFilteredArchivedCards
    const filteredArchivedCards = this.getFilteredArchivedCards().filter(
      (card) => card.id !== cardId,
    );
    if (filteredArchivedCards.length > 0) {
      const card = filteredArchivedCards[0];

      this.setState({
        flyoutCard: {
          id: card.id,
          columnId: card.columnId || null,
        },
      });
    } else {
      // close the flyout if there are no more cards
      this.setState({
        flyoutCard: null,
      });
    }

    if (this.props.board.archivedCards && this.props.setArchivedCards) {
      this.props.setArchivedCards(
        this.props.board.archivedCards.filter((card) => card.id !== cardId),
      );
    }
  };

  setCardRef = (cardId: string, cardRef: React.RefObject<HTMLDivElement>) => {
    this.cardRefs[cardId] = cardRef;
  };

  selectCard = (columnId: string | null, cardId: string) => {
    const card = this.props.board.archivedCards?.find(
      (card) => card.id === cardId,
    );
    if (!card) throw new Error('Card not found');
    addCardToUrl(this.props, card, this.props.board.cardNrPrefix);

    this.setFlyoutCard(columnId!, card);
  };

  setFlyoutCard = (columnId: string, card: ICard) => {
    // (dragos): Unsure if the timeout is needed; if it is, please explain why
    setTimeout(() => {
      let cardRef = this.cardRefs[card.id];
      scrollToCard(cardRef?.current, getScrollBehavior());
    }, 0);

    this.setState({
      flyoutCard: {
        id: card.id,
        columnId: columnId!,
      },
    });
  };

  clearFlyoutCard = () => {
    this.setState((prevState) => {
      return {
        // We maintain `undefined` state to avoid flashing the flyout when
        // moving from undefined to null
        flyoutCard: prevState.flyoutCard === undefined ? undefined : null,
      };
    });
  };

  private getFilteredArchivedCards = () => {
    return this.props.board.archivedCards
      ? getFilteredItems(
          this.props.board.cardFilter.value || '',
          this.state.archivedFilters,
          this.props.board.archivedCards,
          this.props.board.cardNrPrefix,
        )
      : [];
  };

  addOrRemoveField = (field: string) => {
    if (this.state.archivedFilters.includes(field)) {
      this.setState({
        archivedFilters: this.state.archivedFilters.filter(
          (item) => item !== field,
        ),
      });
    } else {
      this.setState({
        archivedFilters: [...this.state.archivedFilters, field],
      });
    }
  };

  resetFields = () => {
    this.setState({
      archivedFilters: [
        'title',
        'priority',
        'cardNumber',
        'description',
        'member',
        'tag',
      ],
    });
  };

  onFlyoutClose = () => {
    this.setState(
      {
        isClosing: true,
      },
      () => {
        setTimeout(() => {
          const cardRef = this.state.flyoutCard
            ? this.cardRefs[this.state.flyoutCard?.id]
            : null;
          cardRef?.current?.parentElement?.focus();
          removeCardFromUrl(this.props, this.props.board.name);
          this.setState({
            flyoutCard: null,
            isClosing: false,
          });
        }, 200);
      },
    );
  };

  render() {
    const { t } = this.props;
    const filteredArchivedCards = this.getFilteredArchivedCards() ?? [];
    const routerProps = {
      match: this.props.match,
      history: this.props.history,
      location: this.props.location,
    };
    let flyoutCard = null;
    let showFlyout =
      this.state.flyoutCard === undefined
        ? undefined
        : Boolean(this.state.flyoutCard);
    if (this.state.flyoutCard !== null) {
      flyoutCard = this.props.board.archivedCards?.find(
        (card) => card.id === this.state.flyoutCard?.id,
      )!;
    }

    return (
      <div
        className={`board-archive-structure flyout-sibling ${
          Boolean(flyoutCard) ? ' flyout-open' : ''
        }`}
      >
        <BoardTopbar
          boardName={this.props.board.name}
          favorite={this.props.board.favorite}
          routeBoardId={this.props.match.params.routeBoardId}
          toggleFavorite={this.props.toggleFavorite}
          enableFilter={true}
          filterNumber={filteredArchivedCards.length}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          addOrRemoveField={this.addOrRemoveField}
          resetFields={this.resetFields}
        />
        <div className="flex-row fill">
          <div className="column pt-0 pb-0 pl-xs">
            <div className="flex-row">
              <div className="column pb-xs">
                <h2 className="primary-title h3 normalcase">
                  {t('archivedCards')}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-board-component flex-row fill"
          style={{ minHeight: '0' }}
        >
          {this.props.board.archivedCards?.length === 0 && (
            <div className="column card-board-list">
              <div className="card">
                <p className="faint-text">{t('noArchivedCards')}</p>
              </div>
            </div>
          )}
          <div className="column card-board-list list-drag-helper py-0">
            {filteredArchivedCards.length !== 0
              ? filteredArchivedCards.map((card) => {
                  return (
                    <div key={card.id}>
                      <BoardCard
                        disabled={true}
                        columnId={''}
                        boardCard={card}
                        selected={card.id === this.state.flyoutCard?.id}
                        cardNrPrefix={this.props.board.cardNrPrefix}
                        selectedCardData={this.state.selectedCardData}
                        selectCard={this.selectCard}
                        tags={this.props.board.tags}
                        priorities={this.props.board.priorities}
                        members={this.props.board.members}
                        setCardRef={this.setCardRef}
                        {...routerProps}
                      />
                    </div>
                  );
                })
              : this.props.board.archivedCards?.length !== 0 && (
                  <div className="card">
                    <p className="faint-text">{t('noArchivedCardsCriteria')}</p>
                  </div>
                )}
          </div>
        </div>
        <Flyout
          showFlyout={showFlyout}
          isClosing={this.state.isClosing}
        >
          {flyoutCard && (
            <BoardArchivedCardFlyout
              boardCard={flyoutCard}
              columnId={flyoutCard.columnId || null}
              removeCard={this.removeCardFromList}
              restoreColumns={this.state.restoreColumns}
              onClose={this.onFlyoutClose}
              columnTitles={this.props.board.columns.map((c) => {
                return {
                  id: c.id,
                  title: c.title,
                };
              })}
            />
          )}
        </Flyout>
      </div>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  withStyledTranslation('boardArchivedCards')(BoardArchivedCards),
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      board: ctx.board,
      toggleFavorite: ctx.toggleFavorite,
      tags: ctx.board.tags,
      priorities: ctx.board.priorities,
      members: ctx.board.members,
      setArchivedCards: ctx.setArchivedCards,
      addWsListener: ctx.addWsListener,
      removeWsListener: ctx.removeWsListener,
    };
  },
);
