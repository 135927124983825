import React, { ChangeEvent, Component, FormEvent } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import TextBox from '../../controls/TextBox/TextBox';
import NumberControl from '../../controls/NumberControl/NumberControl';
import Button from '../../controls/Button/Button';
import RequestStatus from '../RequestStatus/RequestStatus';
import Joi from 'joi';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';
import { RouteComponentProps } from 'react-router-dom';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { processJoiFieldError } from '../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../common/helpers/processJoiError';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { updateTeamSettings } from '../../../common/api/endpoints/team';
import { TeamDTO } from '../../../common/api/dtos/Team';
import AppContext from '../../../common/contexts/AppContext';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface FormData {
  name: string;
  seats: number;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

type InputValue = ChangeEvent<HTMLInputElement> | number;

interface Props extends WithTranslation {
  data: TeamDTO | null;
  setData: (data: TeamDTO) => void;
  history: any;
}

interface State {
  formStatus: TRequestStatus;
  formSeatsStatus: TRequestStatus;
  formData: FormData;
  formErrors: FormErrors;
  data: TeamDTO | null;
}

export class TeamGeneral extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string()
      .pattern(/^[a-zA-Z0-9 ]+$/)
      .required()
      .trim(true)
      .messages({
        'string.pattern.base': FormErrorMsgs.teamName['string.pattern.base'],
        'string.empty': FormErrorMsgs.string['string.empty'],
        'any.required': FormErrorMsgs.string['any.required'],
      }),
    seats: Joi.number()
      .required()
      .min(2)
      .max(99)
      .messages(FormErrorMsgs.number),
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formStatus: 'idle',
      formSeatsStatus: 'idle',
      formData: {
        name: this.props.data?.name || '',
        seats: this.props.data?.seats || 2,
      },
      formErrors: {},
      data: this.props.data,
    };
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('name', ev.target.value);

  setSeats = (value: InputValue) => {
    const seatsValue =
      typeof value === 'number' ? value : Number(value.target.value);
    this.updateForm('seats', seatsValue);
  };

  handleSubmit = async (e: FormEvent) => {
    const isValid = this.validateForm();
    e.preventDefault();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if (this.props.data) {
        try {
          await updateTeamSettings(this.props.data.id, this.state.formData);
          this.setState({
            formStatus: 'success',
          });
          const teamData = {
            ...this.props.data,
            name: this.state.formData.name,
          };

          this.props.setData(teamData);
        } catch (err) {
          this.setState({
            formStatus: 'error',
          });
          showErrorNotifications(err, this.context.notifications.setMessages);
        }
      } else {
        e.preventDefault();
      }
    }
  };

  handleSubmitSeats = async (e: FormEvent) => {
    const isValid = this.validateForm();
    e.preventDefault();

    if (isValid) {
      this.setState({
        formSeatsStatus: 'loading',
      });

      if (this.props.data) {
        try {
          await updateTeamSettings(this.props.data.id, this.state.formData);
          this.setState({
            formSeatsStatus: 'success',
          });
          const teamData = {
            ...this.props.data,
            seats: this.state.formData.seats,
          };

          this.props.setData(teamData);
        } catch (err) {
          this.setState({
            formSeatsStatus: 'error',
          });
          showErrorNotifications(err, this.context.notifications.setMessages);
        }
      } else {
        e.preventDefault();
      }
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.data !== this.props.data) {
      this.setState({
        formData: {
          name: this.props.data?.name || '',
          seats: this.props.data?.seats || 2,
        },
      });
    }
  }

  render() {
    const { t } = this.props;
    const occupiedSeats =
      (this.props.data?.members.length ?? 0) +
      (this.props.data?.invitees?.length ?? 0);
    const teamSeats = this.state.formData.seats || 0;
    const remainingSeats = teamSeats - occupiedSeats;
    return (
      <>
        <form
          autoComplete="off"
          noValidate={true}
        >
          <div className="flex-row fill">
            <div className="column py-0">
              <Accordion
                accordionSlug="team-information"
                isOpen={true}
                iconClasses="fal fa-circle icon"
                title={t('teamInformationTitle')}
                subheading={t('changeTeamNameSubheading')}
                disabled={true}
                softDisabled={true}
                hideArrow={true}
                history={this.props.history}
              >
                <div className="accordion-row py-sm">
                  <TextBox
                    label={t('nameLabel')}
                    type="text"
                    name="name"
                    id="name"
                    maxLength={50}
                    disabled={this.state.formStatus === 'loading'}
                    onChange={this.setName}
                    value={this.state.formData.name}
                    required={true}
                    br={true}
                    placeholder={this.props.data?.name}
                    onBlur={(ev) =>
                      this.validateFormField(ev.target.name as keyof FormData)
                    }
                    error={this.state.formErrors.name}
                  />
                  <Button
                    className="primary-button"
                    onClick={this.handleSubmit}
                    disabled={this.state.formStatus === 'loading'}
                  >
                    <RequestStatus status={this.state.formStatus} />
                    <span className="text">{t('updateTeamInfoText')}</span>
                  </Button>
                </div>
              </Accordion>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column py-0">
              <Accordion
                accordionSlug="team-seats"
                isOpen={true}
                iconClasses="fal fa-layer-group icon"
                title={t('seatsTitle')}
                subheading={t('seatsSubheading')}
                disabled={true}
                softDisabled={true}
                hideArrow={true}
                history={this.props.history}
              >
                <div className="accordion-row py-sm">
                  <NumberControl
                    label={t('seatsLabel')}
                    name="seats"
                    id="seats"
                    minValue={occupiedSeats > 2 ? occupiedSeats : 2}
                    maxValue={99}
                    placeholder={t('numberOfSeatsPlaceholder')}
                    disabled={
                      this.state.formStatus === 'loading' ||
                      !this.props.data?.owned
                    }
                    onChange={this.setSeats}
                    value={this.state.formData.seats}
                    required={true}
                    br={true}
                    error={this.state.formErrors.seats}
                  />
                  <div className="flex-row fill">
                    <div className="column">
                      <p className="mb-xs">{t('remainingSeats')}</p>
                      <div
                        className="progress-bar-component mb-xs"
                        title={`${(occupiedSeats / teamSeats) * 100}% ${t(
                          'occupied',
                        )}`}
                      >
                        <div className="track pe-none">
                          <div
                            className="progress"
                            style={{
                              width: `${
                                this.props.data
                                  ? 100 - (occupiedSeats / teamSeats) * 100
                                  : 0
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="flex-h-spread">
                        <small className="faint-text">
                          {remainingSeats + ' ' + t('available')}
                        </small>
                        <small className="faint-text">
                          {occupiedSeats + ' ' + t('occupied')}
                        </small>
                      </div>
                      <Button
                        className="primary-button mt-sm"
                        onClick={this.handleSubmitSeats}
                        disabled={
                          this.state.formSeatsStatus === 'loading' ||
                          remainingSeats < 0
                        }
                      >
                        <RequestStatus status={this.state.formSeatsStatus} />
                        <span className="text">
                          {t('updateSeatsButtonText')}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withStyledTranslation('teamGeneral')(TeamGeneral);

TeamGeneral.contextType = AppContext;
