import React, { useState, useEffect } from 'react';

const CoinsOff: React.FC = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <svg
      key={key}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <rect
        width="100"
        height="100"
        fill="transparent"
      />

      <defs>
        <g id="coin_outline">
          <path
            d="
            M -30,0 a 30,10 0 1,0 60,0 l 0,5 a 30,10 0 1,1 -60,0 l 0,-5 Z"
            fill="transparent"
            className="coin_on_side"
            stroke="transparent"
            strokeWidth="2"
          />

          <ellipse
            cx="0"
            cy="0"
            rx="30"
            ry="10"
            fill="transparent"
            stroke="transparent"
            className="coin_on_top"
            strokeWidth="2"
          />
        </g>
      </defs>

      <style>
        {`
        .coin_on_top {
          opacity: 0;
          stroke-dashoffset: 30;
          animation: 0.2s fillTopCoin 0.2s ease forwards, 0.6s drawCoin ease forwards;
        }
        
        .coin_on_side {
          opacity: 0;
          stroke-dashoffset: 30;
          animation: 0.1s fillSideCoin 0.2s ease forwards, 0.6s drawCoin ease forwards;
        }
    
        @keyframes fillTopCoin {
          to {
            fill: currentColor;
            opacity: 1;
          }
        }
    
        @keyframes fillSideCoin {
          to {
            fill: currentColor;
            opacity: 1;
          }
        }
    
        @keyframes drawCoin {
          to {
            stroke-dashoffset: 0;
          }
        }
        `}
      </style>

      <use
        href="#coin_outline"
        x="45"
        y="60"
      >
        <animate
          attributeName="y"
          values="30; 60; 58; 60"
          keyTimes="0; 0.6; 0.7; 1"
          dur="0.6s"
          begin="0s"
          fill="freeze"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="45 0 60; 0 45 60; 0 0 60"
          keyTimes="0; 0.5; 1"
          dur="0.6s"
          begin="0s"
          fill="freeze"
        />
      </use>

      <use
        href="#coin_outline"
        x="50"
        y="50"
      >
        <animate
          attributeName="y"
          values="20; 50; 48; 50"
          keyTimes="0; 0.6; 0.8; 1"
          dur="0.6s"
          begin="0.1s"
          fill="freeze"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 0 60; -5 0 60; 0 0 60"
          keyTimes="0; 0.5; 1"
          dur="0.6s"
          begin="0s"
          fill="freeze"
        />
      </use>

      <use
        href="#coin_outline"
        x="45"
        y="40"
      >
        <animate
          attributeName="y"
          values="10; 40; 38; 40"
          keyTimes="0; 0.6; 0.8; 1"
          dur="0.6s"
          begin="0.2s"
          fill="freeze"
        />
      </use>
    </svg>
  );
};

export default CoinsOff;
