import Joi from 'joi';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';
import TextArea from '../../../controls/TextArea/TextArea';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Accordion from '../../../controls/Accordion/Accordion';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import ComboBox from '../../../controls/ComboBox/ComboBox';

interface FormData {
  name: string;
  description: string;
  cardNrPrefix: string;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
  color: string;
  thumbnail: string;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface RouteParams {
  routeBoardId: string;
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
  pageStatus: TRequestStatus;
  formData: FormData;
  formStatus: TRequestStatus;
  updateForm: <K extends keyof FormData>(field: K, value: FormData[K]) => void;
  handleSubmit: () => void;
}

interface State {
  formErrors: FormErrors;
}

class GeneralInformationTab extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().required().trim(true).messages(FormErrorMsgs.string),
    description: Joi.string().trim(true).allow(null, ''),
    cardNrPrefix: Joi.string().trim(true).allow(null, ''),
    columnCardLimitState: Joi.string().valid('disabled', 'enabled', 'enforced'),
    color: Joi.string().trim(true).allow(null, ''),
    thumbnail: Joi.string().trim(true).allow(null, ''),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.props.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.props.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.props.updateForm('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    this.props.updateForm('description', ev.target.value);
  setcardNrPrefix = (ev: ChangeEvent<HTMLInputElement>) =>
    this.props.updateForm('cardNrPrefix', ev.target.value);
  setColumnLimit = (
    ev: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    value: unknown,
  ) => {
    this.props.updateForm(
      'columnCardLimitState',
      value as 'disabled' | 'enabled' | 'enforced',
    );
  };

  triggerFormSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.props.handleSubmit();
    }
  };

  render() {
    const { t } = this.props;
    const options = [
      { id: 'disabled', label: 'Disabled' },
      { id: 'enabled', label: 'Enabled' },
      { id: 'enforced', label: 'Enforced' },
    ];

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="board-information"
              isOpen={true}
              iconClasses="fal fa-credit-card-blank icon"
              title={t('boardInformation')}
              subheading={t('changeBoardDetails')}
              hideArrow={true}
              disabled={true}
              softDisabled={true}
              history={this.props.history}
              translucent={true}
            >
              <div className="accordion-row py-sm">
                <form
                  onSubmit={this.triggerFormSubmit}
                  autoComplete="off"
                  noValidate={true}
                >
                  <TextBox
                    label={t('name')}
                    type="text"
                    name="name"
                    id="name"
                    maxLength={50}
                    disabled={this.props.formStatus === 'loading'}
                    onChange={this.setName}
                    value={this.props.formData.name}
                    required={true}
                    br={true}
                    onBlur={(ev) =>
                      this.validateFormField(ev.target.name as keyof FormData)
                    }
                    error={this.state.formErrors.name}
                  />
                  <TextArea
                    label={t('description')}
                    id="description"
                    name="description"
                    maxLength={255}
                    disabled={this.props.formStatus === 'loading'}
                    value={this.props.formData.description}
                    onChange={this.setDescription}
                    rows={3}
                    cols={30}
                    br={true}
                    onBlur={(ev) =>
                      this.validateFormField(ev.target.name as keyof FormData)
                    }
                    error={this.state.formErrors.description}
                  />
                  <TextBox
                    label={t('cardNumberPrefix')}
                    type="text"
                    name="cardNrPrefix"
                    id="cardNrPrefix"
                    maxLength={8}
                    disabled={this.props.formStatus === 'loading'}
                    onChange={this.setcardNrPrefix}
                    value={this.props.formData.cardNrPrefix}
                    br={true}
                    onBlur={(ev) =>
                      this.validateFormField(ev.target.name as keyof FormData)
                    }
                    error={this.state.formErrors.cardNrPrefix}
                  />
                  <div className="pb-sm">
                    <small>{t('cardNoPreview')}</small>{' '}
                    <span className="faint-text">
                      #
                      {this.props.formData.cardNrPrefix
                        ? this.props.formData.cardNrPrefix + '-'
                        : ''}
                      42
                    </span>
                  </div>
                  <div>
                    <div className="column px-0">
                      <ComboBox
                        id="tabNav"
                        value={this.props.formData.columnCardLimitState}
                        disabled={false}
                        options={options}
                        onChange={this.setColumnLimit}
                        getValue={(op) => op?.id}
                        getLabel={(op) => op?.label}
                        label={'Column limitation'}
                      />
                    </div>
                  </div>
                  <Button
                    className="primary-button"
                    type="submit"
                    disabled={this.props.formStatus === 'loading'}
                    onClick={(
                      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      this.triggerFormSubmit(ev);
                    }}
                  >
                    <RequestStatus status={this.props.formStatus} />
                    <span className="text">{t('updateInformation')}</span>{' '}
                  </Button>
                </form>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('generalInformationTab')(
  GeneralInformationTab,
);
