import React, { ChangeEvent, FormEvent } from 'react';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import Button from '../../controls/Button/Button';
import TextBox from '../../controls/TextBox/TextBox';
import { AddPriorityDTO, PriorityDTO } from '../../../common/api/dtos/Priority';
import { UserDTO } from '../../../common/api/dtos/User';
import { TSelectedPriority } from '../../../common/types/SelectedPriority';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Joi from 'joi';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';

interface FormData {
  id?: string;
  name: string;
  color: string;
}

interface Props extends WithTranslation {
  legendText: string;
  selectedPriority?: TSelectedPriority;
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    priorityData: AddPriorityDTO | PriorityDTO,
  ) => void;
  loggedUser: UserDTO;
  setSelectedContext?: (context: TContextMenu) => void;
}

interface State {
  formData: FormData;
  formErrors: { [key in keyof FormData]?: string };
}

const schema = Joi.object({
  id: Joi.string().allow(''),
  name: Joi.string()
    .pattern(/^[a-zA-Z0-9 ~\-'\.]*$/)
    .min(1)
    .trim()
    .required()
    .messages({
      'string.pattern.base':
        "The name can only contain letters, numbers, spaces, apostrophes ('), hyphens (-), and periods (.)",
      'string.empty': 'The name cannot be empty',
      'any.required': 'The name is required',
    }),
  color: Joi.string().required(),
});

class PriorityForm extends React.Component<Props, State> {
  defaultColor: string;

  constructor(props: Props) {
    super(props);
    this.defaultColor = localStorage.getItem(
      `${this.props.loggedUser.id}-lastPriorityColor`,
    )
      ? (localStorage.getItem(
          `${this.props.loggedUser.id}-lastPriorityColor`,
        ) as string)
      : PRIORITY_COLORS[0].value;

    this.state = {
      formData: {
        id: this.props.selectedPriority?.id || '',
        name: this.props.selectedPriority?.name || '',
        color: this.props.selectedPriority?.color || this.defaultColor,
      },
      formErrors: {},
    };
  }

  validateForm = (): boolean => {
    const { error } = schema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (error) {
      const formErrors: { [key in keyof FormData]?: string } = {};
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof FormData;
        formErrors[key] = detail.message;
      });
      this.setState({ formErrors });
      return false;
    }
    this.setState({ formErrors: {} });
    return true;
  };

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }),
      this.validateForm,
    );
  }

  setPriorityName = (ev: ChangeEvent<HTMLInputElement>) => {
    this.updateFormData('name', ev.target.value);
  };

  setPriorityColor = (value: string) => {
    localStorage.setItem(
      `${this.props.loggedUser.id}-lastPriorityColor`,
      value,
    );
    this.updateFormData('color', value);
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.handleSubmit(e, this.state.formData);
    }
  };

  render() {
    const { t } = this.props;
    const { formData, formErrors } = this.state;
    const translatedColorOptions = PRIORITY_COLORS.map((option) => ({
      ...option,
      label: t(`colorOptions.${option.value}`),
    }));

    return (
      <fieldset id="editPriorityForm">
        <legend>{this.props.legendText}</legend>
        <ul className="control-list-component unwrappable">
          {translatedColorOptions.map((color) => {
            const isSelected = formData.color === color.value;

            return (
              <li key={color.value}>
                <Button
                  className={`secondary-button ${
                    isSelected ? 'active selection-border' : ''
                  }`}
                  value={formData.color}
                  onClick={() => this.setPriorityColor(color.value)}
                  title={color.label}
                >
                  <span className={`pe-none icon accent-text-${color.value}`}>
                    <span className={`cb-only ${color.symbol}`}></span>
                    <span className={`cb-none fas fa-circle`}></span>
                  </span>
                </Button>
              </li>
            );
          })}
        </ul>
        <div className="flex-row fill">
          <div className="column">
            <form onSubmit={this.handleSubmit}>
              <TextBox
                label={t('priorityNameLabel')}
                type="text"
                name="priorityName"
                id="priorityName"
                value={formData.name}
                onChange={this.setPriorityName}
                srOnly={true}
                placeholder={t('priorityNameLabel')}
                error={formErrors.name}
                maxLength={30}
              />
              <ul className="control-list-component">
                <li>
                  <Button
                    className="primary-button"
                    type="submit"
                  >
                    {t('savePriorityButton')}
                  </Button>
                </li>
                <li>
                  <Button
                    className="ghost-button"
                    title={t('closeFormButton')}
                    onClick={() =>
                      this.props.setSelectedContext &&
                      this.props.setSelectedContext('managePriorities')
                    }
                  >
                    <span className="fas fa-times pe-none"></span>
                  </Button>
                </li>
              </ul>
            </form>
            {/* Additional actions like `Delete Priority` */}
            {this.props.children}
          </div>
        </div>
      </fieldset>
    );
  }
}

export default withStyledTranslation('priorityForm')(PriorityForm);
