import React, { Component, KeyboardEvent } from 'react';
import { handleClickOutside } from '../../../common/helpers/handleClickOutside';
import ReactDOM from 'react-dom';
import { relativeParentOffset } from '../../../common/helpers/relativeParentOffset';

type Options<T> = Array<number | string | T>;

interface Props<T> {
  id: string;
  value: unknown;
  options: Options<T>;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  disabledValue?: string | number | T | null;
  classes?: string;
  error?: string;
  formGroupClassname?: string;
  dropDownClasses?: string;
  title?: string;
  srOnly?: boolean;
  fullWidth?: boolean;

  onChange: (
    ev: KeyboardEvent | React.MouseEvent<HTMLLIElement>,
    value: number | string | undefined | T,
  ) => void;
  getValue: (option: T) => unknown;
  getLabel: (option: T) => string;
  getDisabled?: (option: T) => boolean;
  onBlur?: (ev: React.FocusEvent<HTMLButtonElement>) => void;
}

interface State {
  showMenu: Boolean;
  showError: Boolean;
  verticalPos: number | undefined;
  dropUp: boolean;
  minWidth?: number;
  selectedValue: unknown;
  shiftKey: boolean;
  substring: string;
  lastKeyPress: number;
  transformStyle: React.CSSProperties | null;
  positionStyle: React.CSSProperties | null;
  widthStyle: React.CSSProperties | null;
}

class ComboBox<T> extends Component<Props<T>, State> {
  dropdownOptionsRef: React.RefObject<HTMLUListElement>;
  dropdownButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: Props<T>) {
    super(props);
    this.dropdownOptionsRef = React.createRef();
    this.dropdownButtonRef = React.createRef();
    let windownInnerHeight = window.innerHeight;
    const opensAbove =
      this.dropdownButtonRef.current &&
      this.dropdownOptionsRef.current &&
      windownInnerHeight -
        this.dropdownButtonRef.current.getBoundingClientRect().y <
        this.dropdownOptionsRef.current?.offsetHeight + 72;
    this.state = {
      showMenu: false,
      showError: false,
      verticalPos: this.dropdownOptionsRef.current?.offsetHeight,
      selectedValue: null,
      shiftKey: false,
      dropUp: opensAbove ?? false,
      substring: '',
      lastKeyPress: 0,
      transformStyle: null,
      positionStyle: null,
      widthStyle: null,
    };
  }

  toggleMenu = () => {
    this.setState(
      (prevState: State) => {
        return {
          showMenu: !prevState.showMenu,
          selectedValue: !prevState.showMenu ? this.props.value : null,
        };
      },
      () => {
        //Check the position of the DropdownMenu in the page and set the CSS top of the element
        let windownInnerHeight = window.innerHeight;
        const opensAbove =
          this.dropdownButtonRef.current &&
          this.dropdownOptionsRef.current &&
          windownInnerHeight -
            this.dropdownButtonRef.current.getBoundingClientRect().y <
            this.dropdownOptionsRef.current?.offsetHeight + 72;

        if (opensAbove) {
          this.setState({
            verticalPos: 0,
            dropUp: true,
          });
        } else {
          this.setState({
            verticalPos: this.props.label ? 22 : 0,
            dropUp: false,
          });
        }
      },
    );
  };

  handleToggleKeyDown = (ev: KeyboardEvent) => {
    let options = this.props.options;
    let currentValue = this.state.showMenu
      ? this.state.selectedValue
      : this.props.value;
    let getOptionValue = this.props.getValue;
    let dropdownOptions: HTMLUListElement | null =
      this.dropdownOptionsRef.current;
    const currentTime = new Date().getTime();

    let selectedOption: T | unknown;
    let selectedOptionIndex: number = options.indexOf(
      options.find(
        (option) => getOptionValue(option as T) === currentValue,
      ) as T,
    );
    let optionNode: HTMLElement | null | undefined;
    let lastOption = options[options.length - 1];

    const getNextOption = () => {
      let nextIndex = selectedOptionIndex + 1;
      while (
        nextIndex < options.length &&
        this.props.getDisabled?.(options[nextIndex] as T)
      ) {
        nextIndex++;
      }
      if (nextIndex < options.length) {
        let nextOption = options[nextIndex];
        selectedOption = getOptionValue(nextOption as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    const getPrevOption = () => {
      let prevIndex = selectedOptionIndex - 1;
      while (
        prevIndex >= 0 &&
        this.props.getDisabled?.(options[prevIndex] as T)
      ) {
        prevIndex--;
      }
      if (prevIndex >= 0) {
        selectedOption = getOptionValue(options[prevIndex] as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    const getOption = () => {
      selectedOptionIndex = options.indexOf(
        currentValue as string | number | T,
      );
      const normalizedString = (string: string) => {
        return string.normalize('NFD').replace(/\p{Diacritic}/gu, '');
      };

      let optionStartsWithSubstring = options.find((option) =>
        normalizedString(String(this.props.getLabel(option as T)))
          .toLocaleLowerCase()
          .startsWith(normalizedString(this.state.substring)),
      );

      if (optionStartsWithSubstring !== undefined) {
        selectedOption = getOptionValue(optionStartsWithSubstring as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    switch (ev.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        ev.preventDefault();
        getNextOption();
        break;

      case 'ArrowUp':
      case 'ArrowLeft':
        ev.preventDefault();
        getPrevOption();
        break;
      case 'Tab':
        if (this.state.showMenu === true) {
          this.setState({
            showMenu: false,
          });
        }
        break;
      case 'Escape':
        //Close dropdown when pressing Escape/Tab
        this.setState({
          showMenu: false,
        });
        break;

      case ' ':
      case 'CapsLock':
        break;

      case 'Enter':
        if (this.state.showMenu && selectedOption !== undefined) {
          // Check if the selected option is not disabled before proceeding
          if (!this.props.getDisabled?.(selectedOption as T)) {
            this.props.onChange(
              ev as KeyboardEvent,
              this.state.selectedValue as T,
            );
            this.setState({ showMenu: false });
          }
        }
        break;

      default:
        //Select the first option in the options array which starts with key
        if (currentTime - this.state.lastKeyPress < 800) {
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                substring: prevState.substring + ev.key.toLocaleLowerCase(),
              };
            },
            () => {
              getOption();
              selectOption();
            },
          );
        } else {
          this.setState({ substring: ev.key.toLocaleLowerCase() }, () => {
            getOption();
            selectOption();
          });
        }
    }

    const selectOption = () => {
      if (selectedOption !== undefined) {
        if (!this.props.getDisabled?.(selectedOption as T)) {
          if (dropdownOptions?.classList.contains('open')) {
            this.scrollToDropdownOption(optionNode, dropdownOptions);
            this.setState({ selectedValue: selectedOption });
          } else {
            this.props.onChange(ev as KeyboardEvent, selectedOption as T);
          }
        }
      }
    };

    selectOption();
  };

  scrollToDropdownOption(
    option: HTMLElement | null | undefined,
    dropdownOptions: HTMLUListElement | null,
  ) {
    let menuY = dropdownOptions?.getBoundingClientRect().y;
    let optionY =
      option &&
      dropdownOptions &&
      option.getBoundingClientRect().y + dropdownOptions.scrollTop;

    dropdownOptions?.querySelector('.selected')?.classList.remove('selected');
    option && option.classList.add('selected');
    option && option.focus();

    if (menuY && optionY) {
      dropdownOptions?.scrollTo({ top: optionY - menuY - 4, behavior: 'auto' });
    }
  }

  getMinWidth() {
    let elements =
      this.dropdownOptionsRef.current?.querySelectorAll<HTMLSpanElement>(
        'li > span',
      ) as NodeList;

    if (!elements) return;

    const nodeList = Array.from(elements);

    const widths = nodeList.map(
      (listItem) => (listItem as HTMLSpanElement).offsetWidth,
    );
    this.setState({
      //magic numbers and magic timeout
      minWidth:
        (widths.length > 1 ? Math.max(...widths) : Number(widths[0])) + 46,
    });
  }

  computeFixedPosition = () => {
    if (!this.dropdownButtonRef.current) return;

    const dropdownButtonRect =
      this.dropdownButtonRef.current.getBoundingClientRect();
    const borderOffset = 0;
    let buttonHeight = this.dropdownButtonRef.current.clientHeight;
    let buttonWidth = this.dropdownButtonRef.current.offsetWidth;
    buttonHeight += borderOffset;
    const buttonHeightOffset =
      dropdownButtonRect.top > window.innerHeight / 2
        ? -buttonHeight
        : buttonHeight;

    this.setState(
      {
        positionStyle: {
          left: dropdownButtonRect.left,
          top: dropdownButtonRect.top + buttonHeightOffset,
        },
        widthStyle: {
          width: buttonWidth,
        },
      },
      () => {
        // we put this in the callback because we need to make sure we have the correct left & top
        if (!this.dropdownOptionsRef.current || !this.dropdownButtonRef.current)
          return;
        const dropdownButtonRect =
          this.dropdownButtonRef.current.getBoundingClientRect();
        let translateY = `translateY(-${buttonHeight}px)`;
        let translateX = `translateX(calc(-100% + ${buttonWidth}px))`;

        if (relativeParentOffset(this.dropdownButtonRef.current!)) {
          translateX = `translateX(0)`;
        }

        if (dropdownButtonRect.left < window.innerWidth / 2) {
          translateX = `translateX(${borderOffset}px)`;
        }

        if (dropdownButtonRect.top > window.innerHeight / 2) {
          translateY = `translateY(calc(-100% + ${2 * buttonHeight}px)`;
        }

        this.setState({
          transformStyle: {
            transform: translateX + ' ' + translateY,
          },
        });
      },
    );
  };

  handleScrollClose(e: Event) {
    const target = e.target as HTMLElement;
    if (!this.state.showMenu) return;
    if (!target.contains(this.dropdownButtonRef.current)) return;
    handleClickOutside(
      e,
      () => this.setState({ showMenu: false }),
      this.dropdownButtonRef,
    );
  }

  componentDidMount() {
    this.getMinWidth();

    document.addEventListener('mousedown', (ev) =>
      handleClickOutside(
        ev,
        () => this.setState({ showMenu: false }),
        this.dropdownOptionsRef,
        this.dropdownButtonRef,
      ),
    );
    document.addEventListener('keydown', (ev) => {
      const currentTime = new Date().getTime();
      this.setState({ lastKeyPress: currentTime });

      if (ev.shiftKey) {
        this.setState({ shiftKey: true });
      }
    });

    document.addEventListener('keyup', (ev) => {
      this.setState({ shiftKey: false });
    });

    document.addEventListener('scroll', (e) => this.handleScrollClose(e), true);
  }

  //Need to show the error with a small delay because because when clicking an option we trigger option onBlur method which validates the field before the state is changed;
  componentDidUpdate(prevProps: Props<T>, prevState: State) {
    if (this.props.error !== prevProps.error) {
      if (this.props.error !== '' || this.props.error !== undefined) {
        setTimeout(() => {
          this.setState({
            showError: true,
          });
        }, 100);
      } else {
        this.setState({
          showError: true,
        });
      }
    }
    if (this.props.options !== prevProps.options && this.props.options.length) {
      this.getMinWidth();
    }

    if (this.state.showMenu !== prevState.showMenu) {
      if (this.state.showMenu) {
        this.computeFixedPosition();
      } else {
        this.setState({
          positionStyle: null,
          transformStyle: null,
          widthStyle: null,
        });
      }
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener(
      'scroll',
      (e) => this.handleScrollClose(e),
      true,
    );
  }

  render() {
    const valueLabel =
      this.props.options !== undefined &&
      this.props.getLabel(
        this.props.options.find(
          (op) => this.props.getValue(op as T) === this.props.value,
        ) as T,
      );
    const allOptionsDisabled = this.props.options.every(
      (option) => this.props.getDisabled && this.props.getDisabled(option as T),
    );

    let buttonText;

    if (this.props.value !== undefined) {
      buttonText = valueLabel;
    } else {
      buttonText = this.props.disabledValue
        ? this.props.disabledValue
        : this.props.value;
    }

    const errorMessage = (
      <ul className="error-list light">
        <li>{this.props.error}</li>
      </ul>
    );

    // set the height to 0 by default to avoid adding extra space
    // set the height to "auto" when it renders in the portal
    // not sure if height: auto is the best solution here, might need to rethink
    // it should be good as long as the height for the dropdown-options does not change in conduit
    const defaultHeight = this.state.showMenu ? 'auto' : 0;
    const options = (
      <>
        <ul
          className={[
            'dropdown-options',
            this.state.transformStyle &&
            this.state.positionStyle &&
            this.state.widthStyle
              ? 'open'
              : '',
          ].join(' ')}
          ref={this.dropdownOptionsRef}
          style={{
            ...this.state.transformStyle,
            ...this.state.positionStyle,
            ...this.state.widthStyle,
            maxWidth: 'none',
            height: defaultHeight,
          }}
        >
          {this.props.disabledValue && (
            <li className="disabled">
              <span>{this.props.disabledValue}</span>
            </li>
          )}
          {this.props.options?.map((option, index) => {
            const optionValue = this.props.getValue(option as T) as
              | string
              | number;
            return typeof this.props.getDisabled === 'function' &&
              this.props.getDisabled(option as T) === true ? (
              <li
                key={index}
                value={optionValue}
                className={
                  typeof this.props.getDisabled === 'function'
                    ? this.props.getDisabled(option as T) === true
                      ? 'disabled'
                      : ''
                    : ''
                }
                onKeyDown={this.handleToggleKeyDown}
              >
                <span>{this.props.getLabel(option as T)}</span>
              </li>
            ) : (
              <li
                key={index}
                value={optionValue}
                tabIndex={0}
                onClick={(ev) => {
                  if (this.props.value !== optionValue) {
                    this.props.onChange(ev, optionValue);
                  }
                  this.setState({
                    showMenu: false,
                  });
                }}
                onKeyDown={this.handleToggleKeyDown}
              >
                <span>{this.props.getLabel(option as T)}</span>
              </li>
            );
          })}
        </ul>
      </>
    );

    return (
      <>
        {this.props.options.length > 0 && (
          <div className={`form-group ${this.props.formGroupClassname}`}>
            <div
              className={`dropdown  ${this.props.dropDownClasses ?? ''} ${
                this.props.error ? 'error' : ''
              }`}
            >
              {this.props.label && (
                <>
                  <label
                    htmlFor={this.props.id}
                    className={`${this.props.srOnly ? 'sr-only' : ''}`}
                  >
                    {this.props.label}
                    {this.props.required && '*'}
                  </label>
                  {!this.props.srOnly && <br />}
                </>
              )}
              <button
                ref={this.dropdownButtonRef}
                className={`dropdown-toggle ${this.props.classes ?? ''}`}
                style={{
                  width: this.state.minWidth,
                  ...(this.props.fullWidth ? { minWidth: '100%' } : {}),
                }}
                id={this.props.id}
                name={this.props.id}
                title={this.props.title}
                onClick={() => {
                  this.toggleMenu();
                  //Scroll to selected option when opening the dropdown
                  setTimeout(() => {
                    this.scrollToDropdownOption(
                      this.dropdownOptionsRef.current?.querySelector(
                        `[value="${this.props.value}"]`,
                      ),
                      this.dropdownOptionsRef.current,
                    );
                  }, 10);
                }}
                onKeyDown={this.handleToggleKeyDown}
                disabled={this.props.disabled || allOptionsDisabled}
                onBlur={this.props.onBlur}
                type="button"
              >
                {buttonText}
              </button>

              {!this.state.showMenu &&
                !this.state.transformStyle &&
                !this.state.positionStyle &&
                !this.state.widthStyle &&
                options}
              {this.state.showMenu &&
                ReactDOM.createPortal(
                  options,
                  document.querySelector('.dropdown-structure')!,
                )}
            </div>

            {this.state.showError && this.props.error ? errorMessage : ''}
          </div>
        )}
      </>
    );
  }
}
export default ComboBox;
